import React, { useMemo, useState, useEffect } from "react";
import {
  createMuiTheme,
  responsiveFontSizes,
  MuiThemeProvider,
  useMediaQuery,
  CssBaseline,
} from "@material-ui/core";

const ThemeProvider = React.memo(({ children }) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");

  const theme = useMemo(() => {
    let baseTheme = createMuiTheme({
      palette: {
        type: prefersDarkMode ? "dark" : "light",
        common: {
          black: "#073642",
          white: "#EEE8D5",
        },
        secondary: {
          main: "#839496",
        },
        primary: {
          main: "#268BD2",
        },
        error: {
          main: "#DC322F",
        },
        warning: {
          main: "#B58900",
        },
        success: {
          main: "#859900",
        },
        info: {
          main: "#2AA198",
        },
        background: {
          default: prefersDarkMode ? "#002b36" : "#eee8d5",
          paper: prefersDarkMode ? "#003745" : "#fdf6e3",
        },
      },
      typography: {
        fontFamily: "Inconsolata",
      },
    });
    baseTheme = responsiveFontSizes(baseTheme);
    return baseTheme;
  }, [prefersDarkMode]);

  return (
    <div style={{ visibility: mounted ? "" : "hidden" }}>
      <MuiThemeProvider theme={theme}>
        {mounted && <CssBaseline />}
        {children}
      </MuiThemeProvider>
    </div>
  );
});

export default ThemeProvider;
