import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

const DarkLogo = () => {
  const data = useStaticQuery(graphql`
      query {
          logo: file(relativePath: { eq: "Logo.png" }) {
              childImageSharp {
                  fluid(maxWidth: 250, quality: 100) {
                      ...GatsbyImageSharpFluid
                  }
              }
          }
      }
  `);

  return <Img fluid={data.logo.childImageSharp.fluid} />;
};

export default DarkLogo;
