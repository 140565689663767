import React from 'react';
import {
  useMediaQuery,
} from "@material-ui/core";


import DarkLogo from "./DarkLogo";
import LightLogo from "./LightLogo";

const Logo = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  if (prefersDarkMode) {
    return <DarkLogo />;
  }

  return <LightLogo />;
};

export default Logo;
