import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import {
  Drawer,
  Divider,
  List,
  makeStyles,
  Hidden,
  SwipeableDrawer,
} from '@material-ui/core';
import Icon from '@mdi/react';
import { mdiHomeVariant, mdiCodeJson, mdiRss, mdiReact } from '@mdi/js';
import { ItchDotIo } from '@icons-pack/react-simple-icons';
import Logo from '../Logo';

import SidebarItem from './SidebarItem';

const Sidebar = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "Logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 250, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <Hidden smDown implementation="css">
        <Drawer
          classes={{ root: classes.root, paper: classes.root }}
          variant="permanent"
        >
          <Logo />
          <Divider />
          <nav className={classes.nav}>
            <List>
              <SidebarItem to="/" icon={<Icon path={mdiHomeVariant} />}>
                Home
              </SidebarItem>
              <SidebarItem to="/page-2/" icon={<Icon path={mdiCodeJson} />}>
                Go to page 2
              </SidebarItem>
              <SidebarItem to="/blog/" icon={<Icon path={mdiRss} />}>
                Blog
              </SidebarItem>
              <SidebarItem to="/components/" icon={<Icon path={mdiReact} />}>
                Components
              </SidebarItem>
              <SidebarItem
                to="https://caffeinatedcm.itch.io/"
                icon={<ItchDotIo />}
              >
                My itch.io
              </SidebarItem>
            </List>
          </nav>
          <Divider />
          <footer role="contentinfo">
            © {new Date().getFullYear()}, Joshua Letcher
          </footer>
        </Drawer>
      </Hidden>
      <Hidden mdUp>
        <SwipeableDrawer
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
        >
          <Img fluid={data.logo.childImageSharp.fluid} />
          <Divider />
          <nav className={classes.nav}>
            <List>
              <SidebarItem to="/" icon={<Icon path={mdiHomeVariant} />}>
                Home
              </SidebarItem>
              <SidebarItem to="/page-2/" icon={<Icon path={mdiCodeJson} />}>
                Go to page 2
              </SidebarItem>
              <SidebarItem
                to="/using-typescript/"
                icon={<Icon path={mdiRss} />}
              >
                Blog
              </SidebarItem>
              <SidebarItem to="/components/" icon={<Icon path={mdiReact} />}>
                Components
              </SidebarItem>
              <SidebarItem
                to="https://caffeinatedcm.itch.io/"
                icon={<ItchDotIo />}
              >
                My itch.io
              </SidebarItem>
            </List>
          </nav>
          <Divider />
          <footer role="contentinfo">
            © {new Date().getFullYear()}, Joshua Letcher
          </footer>
        </SwipeableDrawer>
      </Hidden>
    </>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '300px',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    padding: theme.spacing(1),
  },
  nav: {
    flexGrow: 1,
  },
}));

export default Sidebar;
