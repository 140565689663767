import React from "react";
import { SvgIcon, ListItem, makeStyles } from "@material-ui/core";
import { navigate } from "gatsby";

const SidebarItem = ({ icon, to, children }) => {
  const classes = useStyles();

  const clickHandler = () => {
    if (to.startsWith("https://")) {
      window.open(to, "", "noopener,noreferrer");
      return;
    }
    navigate(to);
  };

  return (
    <ListItem
      className={classes.root}
      onClick={clickHandler}
      role="link"
      tabIndex={0}
    >
      <SvgIcon className={classes.icon}>{icon}</SvgIcon>
      {children}
    </ListItem>
  );
};

const useStyles = makeStyles(
  theme => ({
    root: {
      display: "flex",
      fontSize: "1rem",
      textTransform: "uppercase",
      alignItems: "center",
      "&:hover": {
        backgroundColor: theme.palette.background.default,
      },
    },
    icon: {
      fontSize: "inherit",
      margin: theme.spacing(1),
    },
  }),
  { name: "SidebarItem" }
);

export default SidebarItem;
