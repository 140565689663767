/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core";

import ThemeProvider from "./ThemeProvider";

import Sidebar from "./Sidebar";

const Layout = ({ children }) => {
  const classes = useStyles();

  return (
    <ThemeProvider>
      <div className={classes.root}>
        <Sidebar />
        <div className={classes.content}>
          <main>{children}</main>
        </div>
      </div>
    </ThemeProvider>
  );
};

const useStyles = makeStyles(
  theme => ({
    root: {
      display: "flex",
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(2, 3),
    },
  }),
  { name: "Layout" }
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
